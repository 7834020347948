import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {Observable} from 'rxjs';
import {WebPlayerUrls} from "../../web-player-urls.service";

@Injectable({
    providedIn: 'root'
})
export class SearchTabValidGuard  {

    readonly validTabs = ['artists', 'albums', 'songs', 'users', 'playlists'];

    constructor(private router: Router, private urls: WebPlayerUrls) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const valid = this.validTabs.indexOf(next.params.tab) > -1;
        if ( ! valid) this.router.navigate(this.urls.search(next.params.query));
        return valid;
    }
}
