import {Injectable} from '@angular/core';
import {AppHttpClient} from '@common/core/http/app-http-client.service';
import {Snip} from '../../models/Snip';
import {BackendResponse} from '@common/core/types/backend-response';
import {PaginationResponse} from '@common/core/types/pagination/pagination-response';
import {PaginatedBackendResponse} from '@common/core/types/pagination/paginated-backend-response';
import {PaginationParams} from '@common/core/types/pagination/pagination-params';
import {Subject} from 'rxjs';
import {tap} from 'rxjs/operators';

export const SNIPS_BASE_URI = 'snips';

export interface GetSnipResponse {
    snip: Snip;
//    comments: PaginationResponse<TrackComment>;
}

@Injectable({
    providedIn: 'root'
})
export class Snips {
    public snipsDeleted$ = new Subject<number[]>();
    constructor(private http: AppHttpClient) {}

    public get(id: number, params: object = {}): BackendResponse<GetSnipResponse> {
        return this.http.get(`${SNIPS_BASE_URI}/${id}`, params);
    }

    public create(payload: Partial<Snip>): BackendResponse<{snip: Snip}> {
        return this.http.post(SNIPS_BASE_URI, payload);
    }

    public update(id: number, payload: Partial<Snip>): BackendResponse<{snip: Snip}> {
        return this.http.put(`${SNIPS_BASE_URI}/${id}`, payload);
    }

    public delete(ids: number[]) {
        return this.http.delete(SNIPS_BASE_URI, {ids}).pipe(tap(() => {
            this.snipsDeleted$.next(ids);
        }));
    }

    /*
    public logPlay(track: Track, params: {queueId?: string}) {
        return this.http.post(`track/plays/${track.id}/log`, params);
    }

    public loadWaveData(id: number): BackendResponse<{waveData: number[][], comments: TrackComment[]}> {
        return this.http.get(`${TRACKS_BASE_URI}/${id}/wave`);
    }

    public loadComments(id: number, params: PaginationParams): PaginatedBackendResponse<TrackComment> {
        return this.http.get(`${TRACKS_BASE_URI}/${id}/comments`, params);
    }
    */
}
