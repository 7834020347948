import {Track} from './Track';

export const SNIP_MODEL = 'snip';

export interface Snip {
    id: number;
    start?: number;
    duration?: number;
    track?: Track;
    plays?: number;
    shares?: number;
    likes?: number;
    comments?: number;
    updated_at?: string;
    created_at?: string;
    model_type: 'snip';
}

