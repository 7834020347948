import {finalize} from 'rxjs/operators';
import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Player} from '../player.service';
import {FullscreenOverlay} from '../../fullscreen-overlay/fullscreen-overlay.service';
import {QueueSidebar} from '../../queue-sidebar/queue-sidebar.service';
import {Lyrics} from '../../lyrics/lyrics.service';
import {Snips} from '../../snips/snips.service';
import {Modal} from '@common/core/ui/dialogs/modal.service';
import {LyricsModalComponent} from '../../lyrics/lyrics-modal/lyrics-modal.component';
import {WebPlayerState} from '../../web-player-state.service';
import {Settings} from '@common/core/config/settings.service';
import {Toast} from '@common/core/ui/toast.service';
import {WebPlayerUrls} from '../../web-player-urls.service';
import {downloadFileFromUrl} from '@common/uploads/utils/download-file-from-url';
import {CurrentUser} from '@common/auth/current-user';
import {ConfirmModalComponent} from '@common/core/ui/confirm-modal/confirm-modal.component';
import {BackendErrorResponse} from '@common/core/types/backend-error-response';

@Component({
    selector: 'player-controls',
    templateUrl: './player-controls.component.html',
    styleUrls: ['./player-controls.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PlayerControlsComponent implements OnInit {
    private snipLength = 15.0;
    public shouldHideLyricsButton = false;
    public shouldHideVideoButton = false;
    public shouldHideDownloadButton = true;
    public loading$ = new BehaviorSubject<boolean>(false);

    constructor(
        public player: Player,
        private lyrics: Lyrics,
        private snips: Snips,
        private modal: Modal,
        public overlay: FullscreenOverlay,
        public queueSidebar: QueueSidebar,
        private webPlayerState: WebPlayerState,
        private settings: Settings,
        private toast: Toast,
        private urls: WebPlayerUrls,
        private currentUser: CurrentUser,
    ) {}

    ngOnInit() {
        this.shouldHideLyricsButton = this.settings.get('player.hide_lyrics') || !this.currentUser.hasPermission('lyrics.view');
        this.shouldHideVideoButton = this.settings.get('player.hide_video_button');
        this.shouldHideDownloadButton = !this.settings.get('player.enable_download') || !this.currentUser.hasPermission('music.download');
    }

    /**
     * Fetch lyrics and show lyrics modal.
     */
    public showLyricsModal() {
        const track = this.player.getCuedTrack();
        if ( ! track) return;

        this.webPlayerState.loading = true;

        this.lyrics.get(track.id).pipe(finalize(() => {
            this.webPlayerState.loading = false;
        })).subscribe(response => {
            this.modal.open(LyricsModalComponent, {lyrics: response.lyric.text}, {panelClass: 'lyrics-modal-container'});
        }, () => {
            this.toast.open('Could not find lyrics for this song.');
        });
    }

    public downloadCurrentTrack() {
        const cued = this.player.getCuedTrack();
        if ( ! cued) return;
        downloadFileFromUrl(this.urls.trackDownload(cued));
    }

    public makeSnip() {
        const track = this.player.getCuedTrack();
        if ( ! track) return;

        let current_secs = this.player.getCurrentTime();
        const duration = this.player.getDuration();
        if (current_secs + this.snipLength > duration) {
            current_secs = duration - this.snipLength;
        }
        if (current_secs < 0) {
            current_secs = 0;
        }

        // convert to milliseconds
        let current_ms = Math.floor(current_secs * 1000);

        let print_min = Math.floor(current_secs / 60);
        let print_sec = Math.floor(current_secs - 60 * print_min);

        this.modal
            .show(ConfirmModalComponent, {
                title: 'Make Snip',
                body: 'Are you sure you want to make a ' + this.snipLength + ' second snip starting at ' +
                     Number(current_secs).toFixed(2) + ' seconds into the song? (https://www.youtube.com/watch?v=' + track.youtube_id +
                    '&t=' + print_min + 'm' + print_sec + 's)',
                ok: 'Make',
            })
            .beforeClosed()
            .subscribe(confirmed => {
                if (confirmed) {
                    let payload = {
                        track_id: track.id,
                        start: current_ms,
                        duration: 15000,
                    };

                    const request = this.snips.create(payload);

                    request
                        .pipe(finalize(() => this.loading$.next(false)))
                        .subscribe(response => {
                            this.toast.open('Snip saved.');
                        }, (errResponse: BackendErrorResponse) => {
                            //this.errors$.next(errResponse.errors);
                            //scrollInvalidInputIntoView(this.errors$.value, `track-form-${this.uniqueId}`);
                        });

                }
            });
    }
}
